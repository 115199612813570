import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';

// import { useMount } from '@sequensis/react-hooks';
import { Paragraph, Spaced, Text } from '@sequensis/stylish-core';
import { PageHeader } from '@sequensis/stylish-layouts';

// import { useAnalyticsCookieContext } from 'src/components/Analytics/AnalyticsCookieController';
// import { showDelightedSurvey } from 'src/components/Delighted';
import { useProduct } from 'src/hooks/useProduct';
import { useRefreshAccount } from 'src/hooks/useRefreshAccount';
// import { getAccountId } from 'src/store/Account/selectors';
import {
  // getAccountReference,
  getCurrentAccountIdFromProduct,
  getIsEligibleForPaymentPlan,
  getIsInArrears,
  getIsInPaymentPlan,
  getIsOnRepaymentBreak,
  getLoanAmount,
  getProductIsComplete,
  getProductIsPaidOff,
} from 'src/store/Accounts';

import { CurrentBalance } from './Components/CurrentBalance';
import CurrentPaymentPlan from './Components/CurrentPaymentPlan';
import { LoanPaidOff } from './Components/LoanPaidOff';
import { MissedPayments } from './Components/MissedPayments';
import { NextPayment } from './Components/NextPayment';
import { PreviousLoan } from './Components/PreviousLoan';
import RemainingPayments from './Components/RemainingPayments';
import { RepaymentBreakStatus } from './Components/RepaymentBreakStatus';
import { Support } from './Components/Support';

interface BalanceProps {
  isPreComputedProduct: string;
}

export const Balance = ({ isPreComputedProduct }: BalanceProps) => {
  const navigate = useNavigate();
  // const { consented } = useAnalyticsCookieContext();

  // const accountId = useSelector(getAccountId);
  // const accountReference = useSelector(getAccountReference(accountId ?? ''));

  // useMount(() =>
  //   showDelightedSurvey(consented, {
  //     properties: {
  //       loanRef: accountReference ?? '',
  //     },
  //   }),
  // );

  const handleCurrentBalanceClick = () => navigate('details');

  const product = useProduct();
  const currentAccountId = useSelector(getCurrentAccountIdFromProduct(product)) ?? '';

  const isInArrears = useSelector(getIsInArrears(currentAccountId));
  const isProductComplete = useSelector(getProductIsComplete(currentAccountId));
  const isProductPaidOff = useSelector(getProductIsPaidOff(currentAccountId));
  const isEligibleForPaymentPlan = useSelector(
    getIsEligibleForPaymentPlan(currentAccountId),
  );
  const isInPaymentPlan = useSelector(getIsInPaymentPlan(currentAccountId));
  const isOnRepaymentBreak = useSelector(getIsOnRepaymentBreak(currentAccountId));
  const loanAmount = useSelector(getLoanAmount(currentAccountId));

  const { refreshAccount } = useRefreshAccount(currentAccountId);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('accountRefresh')) {
      refreshAccount();

      searchParams.delete('accountRefresh');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams, refreshAccount]);

  if (!product) {
    navigate('/customer/overview');
    return null;
  }

  const { displayBorrowMore, displayRemainingPayments } = product.balance;

  const displayRemainingPaymentsEnabled = displayRemainingPayments === 'true';

  const displayBorrowMoreEnabled = displayBorrowMore === 'true';

  const shouldDisplayRemainingPayments =
    !isInArrears && !isInPaymentPlan && !isProductPaidOff;

  const shouldDisplayRepaymentBreakStatus =
    product.displayRepaymentBreakStatus && isOnRepaymentBreak;

  return (
    <>
      <PageHeader>
        <Paragraph fontWeight={300} verticalAlign="center" color="backgroundPair">
          Loan amount: <Text fontWeight="bold">£ {loanAmount}</Text>
        </Paragraph>
        <Paragraph fontWeight={300} verticalAlign="center" color="backgroundPair">
          Product:
          <Text fontWeight="bold">
            {isPreComputedProduct
              ? ' Pre-Calculated Interest Loan'
              : ' Daily Interest Loan'}
          </Text>
        </Paragraph>
      </PageHeader>

      <Spaced marginBottom={3}>
        {isProductPaidOff && <LoanPaidOff productName={product.name} />}
        {isInPaymentPlan && <CurrentPaymentPlan />}
        {isInArrears && <MissedPayments />}
        {isProductPaidOff === false && (
          <CurrentBalance
            onClick={handleCurrentBalanceClick}
            displayMissedPayments={isInArrears}
            displayBorrowMore={
              displayBorrowMoreEnabled && !isInArrears && !isInPaymentPlan
            }
            displaySettleMyLoan={!isProductComplete && !isProductPaidOff}
          />
        )}
        {shouldDisplayRepaymentBreakStatus && <RepaymentBreakStatus />}
        {displayRemainingPaymentsEnabled && shouldDisplayRemainingPayments && (
          <RemainingPayments />
        )}
        {!isProductComplete && !isProductPaidOff && <NextPayment />}
        {isProductPaidOff && <PreviousLoan onClick={handleCurrentBalanceClick} />}
        {isEligibleForPaymentPlan && <Support />}
      </Spaced>
    </>
  );
};

export default Balance;
