import { PageHeader } from '@sequensis/stylish-layouts';

import { useProduct } from 'src/hooks/useProduct';

import { BalanceCard, DetailCard, RemainingCard } from './Components';

interface BalanceDetailsProps {
  isPreComputedProduct: string;
}

export const BalanceDetails = ({ isPreComputedProduct }: BalanceDetailsProps) => {
  const {
    balance: { showProgressBarsOnBalanceDetail },
  } = useProduct();

  return (
    <>
      <PageHeader title="About your loan" />
      <DetailCard />
      <RemainingCard isPreComputedProduct={isPreComputedProduct} />
      {showProgressBarsOnBalanceDetail === 'true' && <BalanceCard />}
    </>
  );
};
