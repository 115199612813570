import { useSelector } from 'react-redux';

import {
  faChartLine,
  faClock,
  faCreditCard,
  faLifeRing,
  faMoneyBillWave,
  faUmbrellaBeach,
} from '@fortawesome/pro-regular-svg-icons';
import { useFeatureFlag } from '@sequensis/react-config';
import { Box, Flex, Text } from '@sequensis/stylish-core';
import { Navigation } from '@sequensis/stylish-layouts';

import { DynamicLogo } from 'src/components/Logos/DynamicLogo';
import {
  getAccountFetched,
  getAccountReference,
  getAccountStatus,
  getCanHaveRepaymentBreaks,
  getIsEligibleForPaymentPlan,
  getProductIsComplete,
  getProductIsPaidOff,
} from 'src/store/Accounts/selectors';
import { getAccountForProduct } from 'src/store/Customer/selectors';
import { AccountStatus } from 'src/types/AccountProps';
import { Product } from 'src/types/ConfigProps';
import { ModuleRouteProps } from 'src/types/ModuleRouteProps';

interface ProductNavigationProps {
  product: Product;
}

export const ProductNavigation = ({ product }: ProductNavigationProps) => {
  const account = useSelector(getAccountForProduct(product));

  const accountReference = useSelector(getAccountReference(account?.id ?? ''));

  const hasLoadedAccount = useSelector(getAccountFetched(account?.id ?? ''));
  const accountState = useSelector(getAccountStatus(account?.id ?? ''));
  const isProductComplete = useSelector(getProductIsComplete(account?.id ?? ''));
  const isProductPaidOff = useSelector(getProductIsPaidOff(account?.id ?? ''));
  const canHaveRepaymentBreaks = useSelector(
    getCanHaveRepaymentBreaks(account?.id ?? ''),
  );
  const isEligibleForPaymentPlan = useSelector(
    getIsEligibleForPaymentPlan(account?.id ?? ''),
  );

  const allowRepaymentBreaks = useFeatureFlag('allowRepaymentBreaks');
  const settlementsEnabled = useFeatureFlag('enableSettlements');

  const isActive = accountState === AccountStatus.Active;

  // capture session for struggling-to-pay menu click
  const captureSessionEvent = () => {
    localStorage.setItem('struggle-to-pay', 'true');
  };

  const accountRoutes: ModuleRouteProps[] = [
    {
      icon: faChartLine,
      path: `/${product.baseRoute}/balance`,
      title: 'Balance',
      testId: `${product.baseRoute}-your-loan`,
      hide: !hasLoadedAccount,
      disabled: !isActive,
    },
    {
      icon: faMoneyBillWave,
      path: `/${product.baseRoute}/payments`,
      title: 'Payments',
      hide: isProductComplete || isProductPaidOff || !hasLoadedAccount,
      disabled: !isActive,
      testId: `${product.baseRoute}-direct-debit`,
    },
    {
      icon: faCreditCard,
      path: `/${product.baseRoute}/settlement`,
      title: 'Settle loan',
      hide:
        !settlementsEnabled || isProductComplete || isProductPaidOff || !hasLoadedAccount,
      disabled: !isActive,
      testId: `${product.baseRoute}-settle-loan`,
    },
    {
      icon: faClock,
      path: `/${product.baseRoute}/transactions`,
      title: 'Transactions',
      hide: !hasLoadedAccount,
      disabled: !isActive,
      testId: `${product.baseRoute}-transactions`,
    },
    {
      icon: faLifeRing,
      path: `/${product.baseRoute}/payment-plan/struggling-to-pay`,
      title: 'Struggling to pay',
      hide: !isEligibleForPaymentPlan || !hasLoadedAccount,
      disabled: !isActive,
      testId: `${product.baseRoute}-struggling-to-pay`,
      onClick: captureSessionEvent,
    },
    {
      icon: faUmbrellaBeach,
      path: `/${product.baseRoute}/payment-plan`,
      title: 'Repayment breaks',
      hide:
        product.allowRepaymentBreaks === 'false' ||
        canHaveRepaymentBreaks === false ||
        allowRepaymentBreaks === false,
      disabled: !isActive,
      testId: `${product.baseRoute}-payment-plan`,
    },
  ];

  const sectionLabel = `${product.brandName} Loan`;

  return (
    <Navigation.Section aria-label={sectionLabel}>
      <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Box width={100}>
          <DynamicLogo productName={product.baseRoute} useDarkText />
        </Box>

        <Flex justifyContent="end" alignItems="flex-end" flexDirection="column">
          <Text fontSize="xs" lineHeight="xs" fontWeight="bold" color="primaryTint">
            Loan ID:
          </Text>
          <Text fontSize="xs" lineHeight="xs" fontWeight="bold" color="primaryTint">
            {accountReference}
          </Text>
        </Flex>
      </Flex>

      <Navigation.List>
        {accountRoutes.map((route) =>
          route.hide ? null : <Navigation.Item key={route.path} {...route} />,
        )}
      </Navigation.List>
    </Navigation.Section>
  );
};
