import { useSelector } from 'react-redux';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useConfig } from '@sequensis/react-config';
import { Card, Flex, getColor, Icon, Text } from '@sequensis/stylish-core';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { getnumberOfPaymentsRemaining } from 'src/store/Account/selectors';

const linkStyles = css`
  /* Create a custom property to allow animating underline offset */
  @property --offset {
    syntax: '<length>';
    inherits: false;
    initial-value: 3px;
  }

  color: ${getColor('primary')};
  text-decoration: underline;
  text-decoration-color: ${getColor('background')};
  text-underline-offset: var(--offset);
  transition: --offset 200ms, text-decoration-color 200ms;

  &:hover {
    --offset: 5px;
    text-decoration-color: ${getColor('backroundPair')};
  }

  /* Fallback to only animating the colour for browsers that dont support @property */
  @supports not (background: paint(something)) {
    text-underline-offset: 5px;
    transition: text-decoration-color 200ms;

    &:hover {
      text-underline-offset: 5px;
    }
  }
`;

const A = styled.a<SpaceProps>`
  ${linkStyles}
  ${space}

  &:focus, &:focus-visible {
    outline: ${getColor('accessibility')} solid 4px;
  }
`;
interface RemainingCardProps {
  isPreComputedProduct: string;
}

export const RemainingCard = ({ isPreComputedProduct }: RemainingCardProps) => {
  const { supportHelpdesk } = useConfig();
  const remainingRepayments = useSelector(getnumberOfPaymentsRemaining);

  return (
    <Card heading="Remaining payments" marginY={2}>
      <Flex alignItems="center" marginBottom={1}>
        <Text fontWeight="bold" fontSize="large" fontFamily="heading">
          {remainingRepayments} payments to go
        </Text>
      </Flex>
      <Flex alignItems="center" marginBottom={1}>
        Based on your current payment schedule
      </Flex>
      <Flex color="primary" alignItems="center" marginBottom={1}>
        <Icon icon={faInfoCircle} size="sm" mr="2" maxHeight={16} />
        {isPreComputedProduct ? (
          <>
            <Text fontWeight="bold">
              If you wish to make an additional payment towards your loan to reduce your
              monthly payments, or make an additional payment to pay your loan off sooner,{' '}
              <A href={supportHelpdesk} target="_blank" rel="noopener noreferrer">
                <Text fontWeight="bold">contact our support team</Text>
              </A>
              .
            </Text>
          </>
        ) : (
          <Text fontWeight="bold">
            You can pay off your loan sooner by paying more each month
          </Text>
        )}
      </Flex>
    </Card>
  );
};
